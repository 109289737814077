const StickyFormButtons = ({
  children,
  elementARef,
  elementBRef,
  hasPassed,
}) => (
  <>
    <div ref={elementARef}></div>
    <div
      className={`form__buttons sticky bottom-6 ${
        !hasPassed && "rounded border border-black bg-bgColor p-4	 shadow-2xl"
      }`}
      ref={elementBRef}
    >
      {children}
    </div>
  </>
);

export default StickyFormButtons;
