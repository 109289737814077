import { Dialog } from "@material-ui/core";
import { Form } from "@remix-run/react";

type Props = {
  onCancel?: () => void;
  isOpen: boolean;
  text: string;
  children: React.ReactNode;
  id: string;
  method: "get" | "post" | "delete";
  route?: string;
  action?: string;
  _action?: string;
};

const CTAModal = ({
  onCancel,
  isOpen,
  text,
  children,
  id,
  method,
  route,
  action,
  _action,
}: Props) => {
  return (
    <Dialog
      className="grid place-items-center"
      open={isOpen}
      onClose={onCancel}
    >
      <Form
        method={method}
        action={action}
        className="min-w-[300px] max-w-[400px]"
      >
        <p className="mb-5 text-center">{text}</p>
        <input hidden name="id" value={id} readOnly />
        <input hidden name="route" value={route} readOnly />
        <input hidden name="_action" value={_action} readOnly />
        <div className="grid gap-2.5">{children}</div>
      </Form>
    </Dialog>
  );
};

export default CTAModal;
