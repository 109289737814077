import { useEffect } from "react";

const useSetTokenToLS = (token: string) => {
  useEffect(() => {
    if (window) {
      window.localStorage.setItem("token", token);
    }
  }, [token]);
};

export default useSetTokenToLS;
