import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { z, ZodSchema } from "zod";
import { formatZodError, IsObject } from "~/utils/tsUtils";
import {
  ApiResponse,
  createErrorResponse,
  createSuccessResponse,
  getErrorFromAxiosException,
  getNodeEnvValue,
  HttpHeaders,
  NodeEnv,
  Path,
  ReqData,
  HEADERS,
  RequestParams,
} from "./utils";

function getNodeEnvClient(): NodeEnv {
  // window.env is set in root.jsx
  //  ENV: { REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL, NODE_ENV: process.env.NODE_ENV, }
  if (!("env" in window && IsObject(window.env) && "NODE_ENV" in window.env)) {
    throw new Error("Couldn't get NODE_ENV from window.env");
  }
  const envNodeValue = getNodeEnvValue(window.env.NODE_ENV);
  if (envNodeValue === undefined) {
    throw new Error(`Couldn't get valid NODE_ENV from window env`);
  }
  return envNodeValue;
}

export const getBaseUrlOnClient = (): string => {
  if (getNodeEnvClient() === "development") {
    // return "http://localhost:9000/cms";
    // return "https://mobile.kptncook.com/cms";
    return "https://mobiletest.kptncook.com/cms";
  }
  // window.env is set in root.jsx
  //  ENV: { REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL, NODE_ENV: process.env.NODE_ENV, }
  if (
    !(
      "env" in window &&
      IsObject(window.env) &&
      "REACT_APP_API_BASE_URL" in window.env
    )
  ) {
    throw new Error("Couldn't get REACT_APP_API_BASE_URL from window.env");
  }
  const baseUrl = window.env.REACT_APP_API_BASE_URL;
  if (typeof baseUrl !== "string") {
    throw new Error(
      `Couldn't get REACT_APP_API_BASE_URL from environment variables`
    );
  }
  return baseUrl;
};

const executeRequest = (
  method: Method,
  path: Path,
  data: ReqData | undefined,
  customHeader: HttpHeaders = {},
  params?: RequestParams
): Promise<AxiosResponse<unknown>> => {
  const token = window.localStorage.getItem("token");

  const config: AxiosRequestConfig = {
    url: `${getBaseUrlOnClient()}${path}`,
    method,
    headers: { ...{ token }, ...HEADERS, ...customHeader },
    validateStatus: (status: number) => status < 500,
    data,
    params,
  };

  return axios(config);
};

const ErrorBodySchema = z.object({
  message: z.string(),
});

const validateResponse = <SchemaOutput, SchemaInput>(
  response: AxiosResponse<unknown>,
  responseSchema: ZodSchema<SchemaOutput, any, SchemaInput>,
  expectedCode: number
): ApiResponse<SchemaOutput> => {
  if (response.status !== expectedCode) {

    console.log('response', response)
    // If the API returns us an error message, we should return it
    const parsedErrorBody = ErrorBodySchema.safeParse(response.data);
    if (parsedErrorBody.success) {
      return createErrorResponse({
        status: response.status,
        errorMessage: parsedErrorBody.data.message,
      });
    }

    // If the API doesn't return an error message, we return a generic error message
    return createErrorResponse({
      status: response.status,
      errorMessage: `Expected status code ${expectedCode}, but got ${response.status
        } with body: ${JSON.stringify(response.data)}`,
    });
  }

  const validatedData = responseSchema.safeParse(response.data);
  if (!validatedData.success) {
    return createErrorResponse({
      errorMessage: `Error while parsing response data: ${formatZodError(
        validatedData.error
      )}`,
      status: 500,
    });
  }

  return createSuccessResponse(validatedData.data);
};

export const executeAndValidateRequest = async <SchemaOutput, SchemaInput>(
  method: Method,
  path: Path,
  data: ReqData | undefined,
  responseSchema: ZodSchema<SchemaOutput, any, SchemaInput>,
  expectedCode: number,
  customHeader: HttpHeaders = {},
  params?: RequestParams
): Promise<ApiResponse<SchemaOutput>> => {
  let response: AxiosResponse<unknown>;
  try {
    response = await executeRequest(method, path, data, customHeader, params);
  } catch (e) {
    return getErrorFromAxiosException(e);
  }

  return validateResponse(response, responseSchema, expectedCode);
};
