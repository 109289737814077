import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Label from "../FormComponents/Label";
import Icon from "@material-ui/core/Icon";

const NewAutoComplete = (props) => {
  const getOptionLabel = (option) => {
    switch (true) {
      case Boolean(option.gdocs && option.title):
        return `${option.gdocs} - ${option.title}`;
      case option.isSponsored:
        return `${option.value || option.title || option.name} - sponsored`;
      case option.value !== undefined:
        return option.value;
      case option.title:
        return option.title;
      case option.name:
        return option.name;
      default:
        return "";
    }
  };

  const getRenderOption = (option) => {
    if (option.gdocs && option.title) {
      return <> {`${option.gdocs} - ${option.title}`}</>;
    } else
      return (
        <>
          {option.value || option.title || option.name}
          {option.isSponsored && <Icon>monetization_on</Icon>}
        </>
      );
  };

  const getOptionSelectedDefault = (option) => {
    return (
      option === props.value ||
      props.setValue ||
      props.setValueEditIng ||
      props.setValueAddIngredient
    );
  };

  return (
    <div className={props.className}>
      {props.label && <Label>{props.label}</Label>}
      <div className="rounded border-[0.5px] border-gray-500">
        <Autocomplete
          defaultValue={props.defaultValue}
          multiple={props.multiple}
          disableCloseOnSelect={props.disableCloseOnSelect}
          filterSelectedOptions={props.filterSelectedOptions}
          onChange={(_, value) => {
            props.onChange(value);
          }}
          onBlur={(_, value) => {
            if (props.onBlur) {
              props.onBlur(value);
            }
          }}
          value={
            props.value ||
            props.setValue ||
            props.setValueEditIng ||
            props.setValueAddIngredient
          }
          options={props.options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={
            props.getOptionSelected ?? getOptionSelectedDefault
          }
          getOptionDisabled={() =>
            props.fromStepTemp || props.disable === true ? true : false
          }
          disableClearable={props.disable}
          renderInput={(params) =>
            props.Errborder ? (
              <ErrorTextField {...params} variant="outlined" />
            ) : (
              <CssTextField
                {...params}
                placeholder={props.placeholder}
                variant="outlined"
              />
            )
          }
          renderOption={getRenderOption}
        />
      </div>
    </div>
  );
};

const CssTextField = withStyles({
  "@global": {
    '.MuiAutocomplete-option[aria-selected="true"] ': {
      backgroundColor: " #f2f2ea",
    },
  },
  root: {
    ".MuiAutocomplete-popupIndicator": {
      color: "red",
      backgroundColor: "blue",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(54, 52, 49, 0.2)",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "rgba(54, 52, 49, 0.2)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(54, 52, 49, 0.2)",
        border: "1px solid rgba(54, 52, 49, 0.2)",
      },
    },
  },
})(TextField);

const ErrorTextField = withStyles({
  root: {
    ".MuiAutocomplete-popupIndicator": {
      color: "red",
      backgroundColor: "blue",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#f45454",
      },
      "&:hover fieldset": {
        borderColor: "#f45454",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#f45454",
        border: "1px solid #f45454",
      },
    },
  },
})(TextField);

export default NewAutoComplete;
