import MoreVertIcon from "@material-ui/icons/MoreVert";
import { MouseEvent } from "react";

type Props = {
  onClick: (event: MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const MoreButtonVertical = ({ onClick }: Props) => (
  <div onClick={onClick}>
    <MoreVertIcon
      className="cursor-pointer p-1 text-primary-100"
      style={{
        width: "36px",
        height: "40px",
      }}
    />
  </div>
);
export default MoreButtonVertical;
