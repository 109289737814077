import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

const FormAutoComplete = ({
  Field,
  control,
  watch,
  fieldName,
  rules,
  errors,
  options,
  labelTitle,
  defaultValue,
  className,
  handleChange,
  multiple,
  disableCloseOnSelect,
  isDisabled,
  filterSelectedOptions,
  optionDisabled,
  getCustomLabel,
}) => {
  const currentValue = watch(fieldName);
  const [hiddenValue, setHiddenValue] = useState(currentValue ?? "");

  useEffect(() => {
    setHiddenValue(watch(fieldName) ?? "");
  }, [watch(fieldName)]);

  return (
    <>
      <Field name={fieldName} label={labelTitle} className={className}>
        {({ Label, Error }) => (
          <>
            <Label>
              {labelTitle}
              {rules?.required && <sup style={{ color: "red" }}> *</sup>}
            </Label>
            <Controller
              control={control}
              name={fieldName}
              rules={rules}
              render={({ field: { onChange, onBlur, ref } }) => {
                return (
                  <div
                    className={` h-full w-full rounded-md border-0 border-none bg-white bg-opacity-50 text-gray-800 shadow-sm outline-none ring-[0.5px] ring-inset ring-gray-800 placeholder:text-gray-800 focus:ring-2 focus:ring-inset focus:ring-primary-100  disabled:bg-transparent;
                  ${className}`}
                  >
                    <Autocomplete
                      ref={ref}
                      options={options}
                      disabled={isDisabled}
                      disableCloseOnSelect={disableCloseOnSelect}
                      multiple={multiple}
                      filterSelectedOptions={filterSelectedOptions}
                      defaultValue={defaultValue ?? null}
                      getOptionLabel={(option) => {
                        if (getCustomLabel) {
                          return getCustomLabel(option);
                        }
                        return option.value;
                      }}
                      getOptionSelected={(option, selected) =>
                        option.id === selected.id
                      }
                      getOptionDisabled={optionDisabled}
                      onBlur={onBlur}
                      onChange={(_, data) => {
                        onChange(
                          multiple
                            ? data.map((item) => item.id)
                            : data?.id ?? null
                        );
                        handleChange && handleChange(data);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </div>
                );
              }}
            />
            <div className="default_input_error">
              <Error>
                {Boolean(errors && errors[fieldName]) &&
                  errors[fieldName].message}
              </Error>
            </div>
          </>
        )}
      </Field>
      {Array.isArray(hiddenValue) ? (
        hiddenValue.map((value) => (
          <input
            type="text"
            name={`${fieldName}[]`}
            value={value}
            readOnly
            hidden
          />
        ))
      ) : (
        <input
          type="text"
          name={fieldName}
          value={hiddenValue}
          readOnly
          hidden
        />
      )}
    </>
  );
};

export default FormAutoComplete;
