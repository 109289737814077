import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useOutletContext } from "@remix-run/react";
import { LayoutContext } from "./MainLayout";

type Props = {
  title: string;
  children?: React.ReactNode;
  breadcrumb?: string;
};

const Navbar = ({ title, children }: Props) => {
  const [toggleSidebar, isSidebarHidden, breadcrumb] =
    useOutletContext<LayoutContext>();

  return (
    <div
      className={`basic-transition fixed right-0 top-0 z-50 bg-bgColor ${
        isSidebarHidden ? "left-[60px]" : "left-[250px]"
      }`}
    >
      <div className="mx-auto flex h-16 w-[92.5%] items-center justify-between gap-10 border-b-[0.5px] border-solid border-gray-500 py-5">
        <div className="flex gap-4 items-center">
          <button onClick={toggleSidebar} className="hover:text-primary-100 ">
            {isSidebarHidden ? <MenuIcon /> : <MenuOpenIcon />}
          </button>
          <div className="text-xl font-Alianza700 tracking-wider">
            {breadcrumb ? (
              <span>
                <div className="mb-0 line-clamp-1">
                  <Link className="hover:underline " to={`/${breadcrumb}`}>
                    {breadcrumb}
                  </Link>
                  {` > ${title}`}
                </div>
              </span>
            ) : (
              <div className="mb-0 ">{`${title}`}</div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-4">{children}</div>
      </div>
    </div>
  );
};

export default Navbar;
