import { useState } from "react";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f45e45",
  },
}));

type UseBackdropReturn = [
  React.Dispatch<React.SetStateAction<boolean>>,
  () => JSX.Element
];

const useBackdrop = (): UseBackdropReturn => {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setShowBackdrop(false);
  };

  const BackdropComponent = () => (
    <Backdrop
      className={classes.backdrop}
      open={showBackdrop}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return [setShowBackdrop, BackdropComponent];
};

export default useBackdrop;
