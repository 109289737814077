import { useState } from "react";
import { useLocation, useParams, useRevalidator } from "@remix-run/react";
import { SecondaryBtn } from ".";
import {
  executeRequest,
  getErrorHandler,
  getThrowHandler,
} from "~/utils/api_utils";
import { Toast } from "~/types";
import { useToast } from "~/hooks";
import { patchData } from "~/server/api_ts.client";

const routeInfo = {
  authorIntros: "authorintros",
  automatedLists: "discovery/automated",
  ad2AppCampaigns: "ad2app",
  curatedLists: "discovery/curated",
  promocodes: "codes",
  kitchenAppliances: "appliances",
  recipeFilters: "filters",
} as const;

const LokaliseButton = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { revalidate } = useRevalidator();

  const [isFetching, setIsFetching] = useState(false);
  const [toast, setToast] = useState<Toast>();

  const pathName = pathname.split("/")[1];

  const isKeyOfRouteInfo = (key: string): key is keyof typeof routeInfo => {
    return key in routeInfo;
  };

  const route = isKeyOfRouteInfo(pathName) ? routeInfo[pathName] : pathName;

  const handleLokalise = async () => {
    setIsFetching(true);
    try {
      const res = await executeRequest(
        () => patchData(`/${route}/${id}/lokalise`, { id }),
        getThrowHandler(),
        `Fetch Complete`,
        getErrorHandler()
      );
      revalidate();
      setToast(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useToast(toast);

  return (
    <SecondaryBtn
      text={isFetching ? "Fetching..." : "Localise"}
      onClick={handleLokalise}
      disabled={isFetching}
    />
  );
};

export default LokaliseButton;
