import { forwardRef, ReactNode } from "react";
import {
  DeleteForever as Delete,
  ChevronRight,
  GetApp,
  Edit,
} from "@material-ui/icons";
const MaterialTable = require("material-table").default;
import { BasicBtn } from "./Buttons";
import StatusBadge from "./StatusBadge";

type Props = {
  children?: ReactNode;
  data: any;
  page?: number;
  totalCount?: number;
  onChangePage?: (page: number) => void;
  onSearchChange?: () => void;
  onOrderChange?: () => void;
  onFilterChange?: () => void;
  columns: any;
  isSearch?: boolean;
  isSorting?: boolean;
  isFiltering?: boolean;
  isExport?: boolean;
  handleDelete?: (e: any, rowData: any) => void;
  handleExport?: () => void;
  handleEdit?: () => void;
  searchText?: string;
  renderDetailPanel?: any;
  handleRowStyle?: () => void;
  isThirdSortClick?: boolean;
  exportCsv?: () => void;
  title?: string;
  isPaging?: boolean;
  onClick?: () => void;
  buttonText?: string;
  isDisabled?: boolean;
  isButtonDisabled?: boolean;
  tooltip?: string;
  onRowClick?: () => void;
  rowStyle?: any;
  className?: string;
  localization?: any;
  isWindow?: boolean;
  isDense?: boolean;
  status?: string;
  type?: "submit" | "button";
};
export type Ref = HTMLButtonElement;

const Table = forwardRef<Ref, Props>(
  (
    {
      data,
      page,
      totalCount,
      onChangePage,
      onSearchChange,
      onOrderChange,
      onFilterChange,
      columns,
      isSearch,
      isSorting,
      isFiltering,
      isExport,
      handleDelete,
      handleExport,
      handleEdit,
      searchText,
      renderDetailPanel,
      handleRowStyle,
      isThirdSortClick,
      exportCsv,
      title,
      isPaging,
      onClick,
      buttonText,
      isDisabled,
      isButtonDisabled,
      tooltip,
      onRowClick,
      rowStyle,
      className = "col-span-12",
      localization,
      isWindow,
      isDense,
      status,
      children,
    },
    ref
  ) => {
    const currentPage = page !== undefined ? page : 1;

    return (
      <div className={className}>
        {title && (
          <div className="flex items-center gap-2.5 pb-4">
            <span className="basic-title">{title}</span>
            {tooltip}
            {isButtonDisabled || isDisabled || !buttonText ? null : (
              <BasicBtn text={buttonText} onClick={onClick} />
            )}
            {status && <StatusBadge status={status} />}
          </div>
        )}
        {children}
        <div
          style={
            isWindow
              ? { maxHeight: "400px", overflow: "hidden", overflowY: "auto" }
              : undefined
          }
        >
          <MaterialTable
            tableRef={ref}
            localization={localization}
            options={{
              rowStyle: rowStyle,
              draggable: false,
              padding: isDense ?? "default",
              pageSize: 50,
              pageSizeOptions: [50],
              debounceInterval: 700,
              actionsColumnIndex: -1,
              headerStyle: {
                color: "rgba(54, 52, 49, 0.5)",
                textTransform: "uppercase",
                fontSize: "12px",
                fontWeight: "bold",
                backgroundColor: "#f2f2ea",
              },
              search: isSearch ?? false,
              sorting: isSorting ?? false,
              paging: isPaging ?? false,
              filtering: isFiltering ?? false,
              thirdSortClick: isThirdSortClick ?? false,
              searchText: searchText ?? "",
              searchFieldAlignment: "left",
              detailPanelColumnAlignment: "right",
              searchFieldStyle: {
                marginLeft: "0px",
                backgroundColor: "#f2f2ea",
              },
              exportButton: isExport ?? false,
              exportCsv: exportCsv ?? null,
            }}
            columns={columns}
            data={data}
            totalCount={totalCount}
            page={currentPage - 1}
            onChangePage={onChangePage}
            onFilterChange={onFilterChange}
            onSearchChange={onSearchChange}
            onOrderChange={onOrderChange}
            onRowClick={onRowClick}
            title=""
            actions={[
              !!handleEdit &&
                (() => ({
                  icon: isDisabled ? "" : Edit,
                  tooltip: "edit",
                  onClick: isDisabled ? null : handleEdit,
                })),
              !!handleExport &&
                (() => ({
                  icon: GetApp,
                  tooltip: "Export",
                  onClick: handleExport,
                })),
              !!handleDelete &&
                (() => ({
                  icon: isDisabled ? "" : Delete,
                  tooltip: "Delete",
                  onClick: isDisabled ? null : handleDelete,
                })),
            ]}
            detailPanel={
              renderDetailPanel
                ? [
                    {
                      icon: ChevronRight,
                      tooltip: "Show More",
                      render: renderDetailPanel,
                    },
                  ]
                : null
            }
            rowStyle={handleRowStyle}
          />
        </div>
      </div>
    );
  }
);

export default Table;
