import { ReactNode } from "react";
import { Drawer as MuiDrawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowDimensions } from "../hooks";

type AnchorProps = {
  width?: string;
};

const useStyles = makeStyles({
  paperAnchorRight: {
    maxWidth: "75%",
    minWidth: (props: AnchorProps) => (props.width ? "" : "40%"),
    width: (props: AnchorProps) => (props.width ? props.width : ""),
  },
});

type Props = {
  children: ReactNode;
  toggle: (isOpen: boolean) => void;
  isOpen: boolean;
  styleProp?: { width?: string };
};

const Drawer = ({ children, toggle, isOpen, styleProp = {} }: Props) => {
  const defaultDrawerWidth =
    (useWindowDimensions()?.width ?? 1000 < 900) ? "98%" : styleProp?.width;

  let finalStyleProp = styleProp;
  if (styleProp.width) {
    finalStyleProp = {
      ...styleProp,
      width: defaultDrawerWidth,
    };
  }

  const classes = useStyles(finalStyleProp);

  return (
    <MuiDrawer
      anchor="right"
      open={isOpen}
      classes={{
        paperAnchorRight: classes.paperAnchorRight,
      }}
      onClose={() => toggle(false)}
    >
      <div className="p-5">{children}</div>
    </MuiDrawer>
  );
};

export default Drawer;
