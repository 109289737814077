import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import {} from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import { Info } from "@material-ui/icons";

type Props = {
  handleDrag: (result: DropResult) => Promise<void> | void;
  key?: string;
  id?: string;
  type?: string;
  className?: string;
  name?: string;
  tooltipTitle?: string;
  promoted?: boolean;
  children: React.ReactNode;
};

const DnDTable = ({
  handleDrag,
  key = "key",
  id = "id",
  type = "droppable-item",
  className = "draggable-table",
  name,
  tooltipTitle,
  promoted,
  children,
}: Props) => (
  <div className={className}>
    <DragDropContext onDragEnd={handleDrag}>
      {name && (
        <>
          <h5>{name ?? ""}</h5>
          {promoted && (
            <div>
              <Tooltip title={tooltipTitle ?? ""}>
                <Info />
              </Tooltip>
            </div>
          )}
        </>
      )}
      <DropZone key={key} id={id} type={type}>
        {children}
      </DropZone>
    </DragDropContext>
  </div>
);

type DropZoneProps = {
  id: string;
  type: string;
  children: React.ReactNode;
};

const DropZone = ({ id, type, children }: DropZoneProps) => (
  <Droppable droppableId={id} type={type}>
    {(provided) => (
      <div ref={provided.innerRef} {...provided.droppableProps}>
        {children}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default DnDTable;
