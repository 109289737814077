import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { Lang } from "~/types";

type Props = {
  handleLangChange: (selectedLang: Lang) => void;
  defaultLang: Lang;
};

export default function LangSelect({ handleLangChange, defaultLang }: Props) {
  const [lang, setLang] = React.useState(defaultLang);

  const handleChange = (event) => {
    handleLangChange(event.target.value);
    setLang(event.target.value);
  };

  const languages = [
    <MenuItem key="en" value={"en"}>
      EN
    </MenuItem>,
    <MenuItem key="de" value={"de"}>
      DE
    </MenuItem>,
    <MenuItem key="es" value={"es"}>
      ES
    </MenuItem>,
    <MenuItem key="fr" value={"fr"}>
      FR
    </MenuItem>,
    <MenuItem key="pt" value={"pt"}>
      PT
    </MenuItem>,
  ];

  return (
    <FormControl variant="outlined">
      <Select value={lang} onChange={handleChange} input={<KptnSelect />}>
        {languages}
      </Select>
    </FormControl>
  );
}

const KptnSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    border: "none",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    letterSpacing: "0.8px",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "AlianzaSlab700",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      border: "none",
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "none",
    },
  },
}))(InputBase);
