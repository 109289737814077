import { MRT_RowData, MRT_TableInstance } from "material-react-table";
import { z, ZodSchema } from "zod";
import {
  deserializeRemix,
  RemixSerializedType,
  UseDataFunctionReturn,
} from "remix-typedjson";
import { useMatches } from "@remix-run/react";
import { AppData } from "@remix-run/node";

export const convertToArray = (
  val: string,
  fallback: string[] = []
): string[] | undefined => {
  if (typeof val === "string" && val === "") {
    return undefined;
  }
  return typeof val === "string" ? val.split(",") : fallback;
};

export const getUpdatedOrder = <T extends MRT_RowData, S = T>(
  table: MRT_TableInstance<T>,
  data: S[]
): S[] => {
  const tmpData = [...data];
  const { draggingRow, hoveredRow } = table.getState();
  if (hoveredRow && draggingRow && hoveredRow.index !== undefined) {
    const movedItem = tmpData.splice(draggingRow.index, 1)[0];
    if (movedItem === undefined) {
      // Gracefully handle the case where the dragged item is undefined
      return data;
    }
    tmpData.splice(hoveredRow.index, 0, movedItem);
  }
  return tmpData;
};

export const download = async (res: Response, filename: string) => {
  try {
    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw new Error(`Error downloading ${filename}`);
  }
};

const getUTCDate = (localDate: Date) =>
  new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    )
  );

export const convertDateToUTCTimestamp = (localDateString: string) => {
  const localDate = new Date(localDateString);
  return getUTCDate(localDate).valueOf();
};

export function formatZodError<T>(error: z.ZodError<T>) {
  return error.issues
    .map(
      (e) =>
        `{ Path:[${e.path.map((path) => path.toString()).join(", ")}], Code: "${
          e.code
        }", message: "${e.message}" }`
    )
    .join(", ");
}

export const safeParse = <Output>(schema: ZodSchema<Output>, data: unknown) => {
  const result = schema.safeParse(data);
  return result.success
    ? { data: result.data, error: null }
    : { data: null, error: result.error };
};

// FIXME: When we upgrade to Typescript 5.5., we can drop the type predicate (x is object) and use safer "type predicate inference"
export function IsObject(x: unknown): x is object {
  return typeof x === "object" && x !== null;
}

// FIXME: When we upgrade to Typescript 5.5., we can drop the type predicate (x is object) and use safer "type predicate inference"
export function isNotNullish<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function getValueFromOptions<U>(
  options: readonly U[],
  value: unknown
): U | undefined {
  return options.find((code) => code === value);
}

export type Success<T> = { success: true; failure: false; result: T };
export const success = <T>(result: T): Success<T> => ({
  success: true,
  failure: false,
  result,
});
export type Failure<E> = { success: false; failure: true; error: E };
export const failure = <E>(error: E): Failure<E> => ({
  success: false,
  failure: true,
  error,
});
export type Result<T, E> = Success<T> | Failure<E>;

export type Primitive =
  | bigint
  | boolean
  | null
  | number
  | string
  | symbol
  | undefined;

export type PlainObject = Record<string, Primitive>;

export function getPaginatedSchema<Output>(schema: ZodSchema<Output>) {
  return z.object({
    results: schema,
    total: z.number(),
    limit: z.number(),
  });
}

export function getOptionalPaginatedSchema<Output>(schema: ZodSchema<Output>) {
  return z.object({
    results: schema,
    total: z.number().optional(),
    limit: z.number().optional(),
  });
}

// Need to wait for https://github.com/colinhacks/zod/issues/2966, until .unknown() becomes more useful, then we can drop .refine & .transform
export const unknownPaginatedSchema = z
  .object({
    results: z
      .unknown()
      .refine((x) => x !== undefined, "result field required"),
    total: z.number(),
    limit: z.number(),
  })
  .transform(({ results, total, limit }) => ({
    results: results as unknown,
    total,
    limit,
  }));

export type UnknownPaginatedData = z.infer<typeof unknownPaginatedSchema>;

// Need to wait for https://github.com/colinhacks/zod/issues/2966, until .unknown() becomes more useful, then we can drop .refine & .transform
export const unknownOptionalPaginatedSchema = z
  .object({
    results: z
      .unknown()
      .refine((x) => x !== undefined, "result field required"),
    total: z.number().optional(),
    limit: z.number().optional(),
  })
  .transform((parseResult) => ({
    results: parseResult.results as unknown,
    total: parseResult.total,
    limit: parseResult.limit,
  }));

export type UnknownOptionalPaginatedData = z.infer<
  typeof unknownOptionalPaginatedSchema
>;

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const truncateTitle = (title: string): string => {
  const maxLength = 50;
  if (title.length > maxLength) {
    return title.substring(0, maxLength) + "...";
  }
  return title;
};

export function useTypedRouteLoaderData<T = AppData>(
  pathname: string
): UseDataFunctionReturn<T> | undefined {
  const match = useMatches().find((match) => match.pathname === pathname);
  if (!match) return undefined;
  return deserializeRemix<T>(match.data as RemixSerializedType<T>) as
    | UseDataFunctionReturn<T>
    | undefined;
}
